import React from 'react'
import Header from '../components/Header'
import Body from '../components/HomePage/Body'

export default function HomePage () {
  return (
    <>
      <Header/>
      <Body/>
    </>
  )
}